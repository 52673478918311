<template>
  <div>
    <Skeleton v-if="loading" class="mb-2" height="6rem" borderRadius="16px"></Skeleton>
    <DataTable v-if="!loading" :value="data" columnResizeMode="fit" class="rd-table">
      <!--  GRANTED    -->
      <Column v-if="type === 'granted'" header="Name" key="name">
        <template #body="slotProps">
          <div class="flex flex-column">
            <div><h3 class="light-blue">{{ slotProps.data.recipient_name }}</h3></div>
            <div class="grey">{{ slotProps.data.recipient_email }}</div>
          </div>
        </template>
      </Column>
      <!--  RECEIVED    -->
      <Column v-if="type === 'received'" header="Name" key="name">
        <template #body="slotProps">
          <div class="flex flex-column">
            <div><h3 class="light-blue">{{ slotProps.data.granter_name }}</h3></div>
            <div class="grey">{{ slotProps.data.granter_email }}</div>
          </div>
        </template>
      </Column>
      <!--   COMMON     -->
      <Column field="relationship" header="Relationship" key="relationship"/>
      <Column header="Type" key="type">
        <template #body="slotProps">
          <div class="flex flex-column">
            <div>{{ slotProps.data.activity_type_shortname }}</div>
            <div class="grey">{{ slotProps.data.activity_type_description }}</div>
          </div>
        </template>
      </Column>
      <Column field="date_added" header="Added" key="added"/>
      <Column field="date_last_access" header="Last Added" key="last_added"/>
      <Column header="Expires On" key="expiry">
        <template #body="slotProps">
          {{ slotProps.data.date_expiry ?? 'No expiry' }}
        </template>
      </Column>

      <Column style="width:20%" header="Actions">
        <template #body="slotProps">
          <div v-if="type === 'granted'" class="action">
            <span @click="deletePermission(slotProps.data)"
                  data-cy="permissions-table-action-delete"
                  class="material-icons grey ml-3 cursor-pointer"
                  v-tooltip.top="'Delete Permission'">delete_forever</span>
          </div>
        </template>
      </Column>

      <template #footer v-if="type === 'granted'">
        <div class="flex justify-content-center clarity-margin-bottom-s">
          <Button class="clarity-btn clarity-gradient-orange" style="padding: 0 45px;"
                  @click="toggleModal">Add New Permission
          </Button>
        </div>
      </template>
    </DataTable>
    <Dialog v-model:visible="showModal.permissiondelete" class="delete-dialog" data-cy="permissions-dialog-delete">
      <div class="flex justify-content-between modal-header">
        <h3 class="blue">Confirm Delete</h3>
        <Button class="clarity-close-modal-btn" @click="dismissConfirm"><span
            class="material-icons-round grey">close</span></Button>
      </div>
      <div class="message">Are you sure you want to delete the permission type:
        {{ selectedPermission ? selectedPermission.activity_type_shortname : '' }} for
        {{ selectedPermission ? selectedPermission.recipient_name : '' }}?
      </div>
      <div class="button-group">
        <Button class="clarity-btn clarity-gradient-grey" @click="dismissConfirm">Dismiss</Button>
        <submit-button data-cy="permissions-dialog-confirm-delete" @submitted="confirmDelete" :loading="loadingState"
                       button-text="Confirm Action" loading-text="Removing Permission..."></submit-button>
      </div>
    </Dialog>
  </div>
</template>

<script>
import api from "@/core/services/api";
import {toDrupalRequest} from "@/core/helpers";
import {useStore} from "vuex";
import {computed, reactive, watch, ref} from "vue";
import SubmitButton from "../common/layout/interactive/SubmitButton";

export default {
  name: "PermissionsTable",
  props: ['data', 'loading', 'type'],
  emits: ['popPermission', 'toggleModal'],
  components: {
    SubmitButton
  },
  setup(props, context) {
    const store = useStore()
    const selectedPermission = ref(null)
    const showModal = reactive({
      permissiondelete: false,
    })

    const activeModal = computed(() => store.getters.currentActiveModal);
    const loadingState = computed(() => store.getters.isLoading('PermissionsTable'))

    watch(activeModal, (value) => {
      // empty object returned if not set
      if (typeof value == 'object') {
        showModal.permissiondelete = false
      } else {
        showModal[value] = true
      }
    })

    const deletePermission = (permission) => {
      selectedPermission.value = permission
      store.dispatch('setActiveModal', {modal: 'permissiondelete'})
    }
    const dismissConfirm = () => {
      selectedPermission.value = null
      store.dispatch('setActiveModal')
    }

    const confirmDelete = async () => {
      store.dispatch('setLoading', {id: 'PermissionsTable', state: true})

      if (process.env.VUE_APP_DELAY_BACKEND_API) {
        await new Promise(r => setTimeout(r, 2000));
      }

      const endpoint = `/permissions/client/investmentpermissionsgranted/${selectedPermission.value.permission_uuid}`;
      const response = await api.delete(`${toDrupalRequest(endpoint)}`);
      if (response) console.log('test response', response.code)
      if (response === undefined) {
        await store.dispatch('addNotification', {
          severity: 'error',
          summary: 'Permission Delete Error',
          detail: `Unable to delete this permission at present. Please try again later.`,
          life: 4000
        })
      } else {
        context.emit('popPermission', selectedPermission.value.permission_uuid)
      }
      store.dispatch('setLoading', {id: 'PermissionsTable', state: false})
      dismissConfirm()
    }

    const toggleModal = () => {
      context.emit('toggleModal')
    }

    return {
      deletePermission,
      toggleModal,
      showModal,
      confirmDelete,
      dismissConfirm,
      selectedPermission,
      loadingState
    }
  }
}
</script>

<style scoped lang="scss">
.button {
  cursor: pointer;
}
.action:hover {
  span {
    color: var(--clarity-blue);
  }
}
</style>