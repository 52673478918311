<template>
  <div class="p-5 flex flex-column">
    <InvestmentTopCard/>

    <spacer-h/>
    <div id="rd3-scrollto"></div>
    <div>
      <content-card-full data-cy="permissions-content">
        <template #title>
          <card-title>
            <template #title><h2>PERMISSIONS</h2></template>
          </card-title>
        </template>
        <template #content-full>
          <div class="flex">
            <!-- LEFT -->
            <div class="flex" style="width: 50%;">
              <div class="flex flex-column justify-content-between ">
                <div>
                  <h3 class="mb-3">MANAGING YOUR PERMISSIONS</h3>
                  <div class="body pr-5">Welcome to the permissions section of our website. This page allows you to
                    manage the valuation permissions you have granted and received. The first list details the users
                    that have granted you permission to view their valuations. The list below confirms the users you
                    have granted permission to see your valuation. Any valuation information you add to the website will
                    be made available to these users. To remove the permissions, select the Delete Permission option and
                    access will be revoked immediately. Add the username of the account that you would like to grant
                    permission to view your valuation. Setting the appropriate permissions allows combined valuation and
                    asset allocation reports to be produced allowing you to access all the information you need from an
                    individual login.
                  </div>
                </div>
                <div class="flex my-5">
                  <Button class="clarity-btn clarity-gradient-orange" @click="toggleAddNewPermissionModal">Add New Permission</Button>
                  <!-- <Button class="clarity-btn clarity-gradient-grey">Read more</Button> -->
                </div>
              </div>
            </div>
            <!-- RIGHT -->
            <div class="flex" style="width: 50%;">
              <div class="portfolioManagementVideo">
                <iframe src="https://player.vimeo.com/video/878609106?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="clarity - Portfolio Management" style="width:100%;height:100%;"></iframe>
              </div>  
            </div>
          </div>

          <spacer-h/>
          <!-- PERMISSIONS TABLES -->
          <div>
            <h3 class="dark-grey">PERMISSIONS GIVEN TO ME</h3>
            <p class="body-text">You have access to Valuations for the following Users:</p>
            <PermissionsTable data-cy="permissions-table-received" class="permissions-table" :data="receivedPermissions" :loading="receivedLoading" type="received"/>
          </div>

          <spacer-h/>

          <div>
            <h3 class="dark-grey">PERMISSIONS GIVEN TO OTHERS</h3>
            <p class="body-text">You have allowed the following users to view your data.</p>
            <p class="body-text bold-em"><span
                class="material-icons dark-grey" style="vertical-align: text-bottom;">info</span> If you no longer want
              to allow the below user access
              to your data, then please click on the <span class="material-icons dark-grey"
                                                          style="vertical-align: text-bottom;">delete_forever</span> "Delete Permission" button.</p>
            <PermissionsTable data-cy="permissions-table-granted" class="permissions-table" :data="grantedPermissions" :loading="grantedLoading" type="granted"
                              @popPermission="removePermission" @toggleModal="toggleAddNewPermissionModal"/>
          </div>
        </template>
      </content-card-full>
    </div>
  </div>
  <AddPermissionModal v-show="showModal === 'addPermission'" @persistPermission="addPermissionToState" />

  <HelpCard mode="investments" class="p-5 pt-0" />
</template>

<script>
import ContentCardFull from '@/components/common/ContentCardFull.vue'
import CardTitle from '@/components/common/CardTitle.vue'
import InvestmentTopCard from "@/components/top_cards/InvestmentTopCard";
import SpacerH from '@/components/common/layout/SpacerH.vue'
import {computed, onMounted, ref} from "vue";
import api from "@/core/services/api";
import {toDrupalRequest} from "@/core/helpers";
import PermissionsTable from "@/components/permissions/PermissionsTable";
import AddPermissionModal from "@/components/modals/AddPermissionModal";
import {useStore} from "vuex";
import HelpCard from "@/components/common/HelpCard";

export default {
  name: "Permissions",
  components: {
    HelpCard,
    AddPermissionModal,
    PermissionsTable,
    CardTitle,
    ContentCardFull,
    InvestmentTopCard,
    SpacerH
  },
  setup() {
    const store = useStore()
    const receivedPermissions = ref([])
    const grantedPermissions = ref([])
    const receivedLoading = ref(true)
    const grantedLoading = ref(true)

    const getGrantedPermissions = async () => {
      if(process.env.VUE_APP_DELAY_BACKEND_API) {
        await new Promise(r => setTimeout(r, 2000));
      }

      const endpoint = '/permissions/client/investmentpermissionsgranted';
      const response = await api.get(`${toDrupalRequest(endpoint)}`);

      if (response.error) {
        console.log('error', response.error)
      }
      grantedPermissions.value = response
      receivedLoading.value = false
    }

    const getReceivedPermissions = async () => {
      if(process.env.VUE_APP_DELAY_BACKEND_API) {
        await new Promise(r => setTimeout(r, 2000));
      }

      const endpoint = '/permissions/client/investmentpermissionsreceived';
      const response = await api.get(`${toDrupalRequest(endpoint)}`);

      if (response.error) {
        console.log('error', response.error)
      }
      receivedPermissions.value = response
      grantedLoading.value = false
    }

    const getPortfolioOptions = () => {
      store.dispatch('getPortfolioOptions')
    }

    const getPolicyOptions = () => {
      store.dispatch('getPolicyOptions')
    }

    onMounted(() => {
      getGrantedPermissions()
      getReceivedPermissions()
      // get portfolio and policy option to ensure dropdowns work - if entry point not via investments of dashboard
      getPortfolioOptions()
      getPolicyOptions()
    })

    const removePermission = (permissionUuid) => {
      grantedPermissions.value = grantedPermissions.value.filter(permission => permission.permission_uuid !== permissionUuid)
    }

    const toggleAddNewPermissionModal = () => {
      store.dispatch('setActiveModal', {modal: 'addPermission'})
    }

    const addPermissionToState = (newPermission) => {
      let today = new Date(Date.now()).toLocaleDateString('en-GB')
      grantedPermissions.value = [...grantedPermissions.value, {
        recipient_name: newPermission.name,
        recipient_email: newPermission.email,
        activity_type: newPermission.activity_type,
        activity_type_shortname: newPermission.activity_type === 'FULL_ACCESS' ? 'All Holdings' : 'Plan Holding',
        activity_type_description: newPermission.activity_type === 'FULL_ACCESS' ? "Full access to all holdings" : "Access to a specific investment plan",
        relationship: newPermission.relationship,
        date_added: today,
        date_last_access: null,
        date_expiry: newPermission.expires,
        policy_fk: newPermission.policy_fk,
        permission_uuid: newPermission.permission_uuid
      }]
    }

    return {
      receivedPermissions,
      grantedPermissions: computed(() => grantedPermissions.value),
      grantedLoading,
      receivedLoading,
      removePermission,
      toggleAddNewPermissionModal,
      addPermissionToState,
      showModal: computed(() => store.getters.currentActiveModal)
    }
  }
}
</script>

<style scoped>
button {
  min-width: 220px;
  justify-content: center;
  margin: 5px 5px 5px 0;
}

.bottom-actions button {
  width: calc(100% / 4);
  padding: 0 45px;
  margin: 0 15px;
}

.body {
  font-size: 18px;
  line-height: 28px;
}

.body-text {
  font-size: 18px;
}

.bold-em {
  font-weight: bold;
  font-style: italic;
}

.permissions-table {
  padding: 20px 0;
}
.portfolioManagementVideo {
  width: 100%;
  height: auto;
  padding: 20px;
}
</style>
