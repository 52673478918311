<template>
  <Modal :width="`70%`" data-cy="permission-modal-add">
    <template #body>
      <content-card-full>
        <template #title>
          <div class="flex justify-content-between title align-items-center">
            <card-title>
              <template #title>
                <div class="flex" style="padding-top: 15px">
                  <h3 class="blue">Add a new permission</h3>
                </div>
              </template>
            </card-title>
            <Button class="clarity-close-modal-btn" @click="closeModal">
              <span class="material-icons-round grey">close</span>
            </Button>
          </div>
          <p class="body-text">
            Please enter the username or email address of the person you will grant permission to.
          </p>
        </template>
        <template #content-full>
          <div class="full-width flex flex-column">
            <div>
              <h3>details</h3>
              <ScrollPanel style="width: 100%; height: 50vh" class="pr-3">
                <div class="filter-set">
                  <InputText data-cy="permission-email" v-model="newPermission.email" class="full-width" style="margin: 10px 0"
                             placeholder="Username or Email Address..."/>
                </div>
                <div v-if="v$.newPermission.email.$error" class="form-error">Please ensure a username or email is
                  provided
                </div>
                <div class="filter-group">
                  <div class="filter-set">
                    <label>Name:</label>
                    <div class="p-inputgroup">
                      <InputText data-cy="permission-name" v-model="newPermission.name" placeholder="Please specify..."
                                 @blur="v$.newPermission.name.$touch"/>
                    </div>
                    <div v-if="v$.newPermission.name.$error" class="form-error">Please ensure a name is provided</div>
                  </div>
                  <div class="filter-set">
                    <label>Relationship:</label>
                    <div class="p-inputgroup">
                      <Dropdown data-cy="permission-relationship" v-model="newPermission.relationship" placeholder="Please select from the list..."
                                :options="relationshipOptions" optionLabel="value" optionValue="value"
                                @blur="v$.newPermission.relationship.$touch"/>
                    </div>
                    <div v-if="v$.newPermission.relationship.$error" class="form-error">Please ensure a relationship is
                      provided
                    </div>
                  </div>
                </div>
                <div class="filter-group">
                  <div class="filter-set">
                    <label>Type:</label>
                    <div class="p-inputgroup">
                      <Dropdown data-cy="permission-portfolio" v-model="selectedPortfolio" placeholder="Please select from the list..."
                                :options="portfolioOptions" optionLabel="name"
                                @change="setType"
                                @blur="v$.newPermission.activity_type.$touch"/>
                    </div>
                    <div v-if="v$.newPermission.activity_type.$error" class="form-error">Please ensure a type is
                      selected
                    </div>
                  </div>
                  <div class="filter-set" data-cy="permission-expiry" >
                    <label>Expiry Date:</label>
                    <span class="wrapper">
                        <Calendar id="basic" v-model="newPermission.expires" dateFormat="dd/mm/yy" autocomplete="off"
                                  panel-class="calendar-valuationdate" :min-date="today" placeholder="No Expiry"
                                  ref="datepicker" class="full-width">
                            <template #footer>
                              <div class="flex flex-row-reverse flex-wrap">
                                <div class="flex align-items-center justify-content-center m-2">
                                  <Button @click="noExpiry"
                                          class="clarity-btn clarity-gradient-orange">No Expiry</Button>
                                </div>
                              </div>
                            </template>
                          </Calendar>
                          <span class="material-icons-round calendar-icon grey">today</span>
                    </span>
                  </div>
                </div>
                <div class="row" v-if="newPermission.activity_type === 'PLAN_ACCESS'">
                  <div class="filter-group">
                    <div class="filter-set">
                      <label>Plan:</label>
                      <div class="p-inputgroup">
                        <Dropdown data-cy="permission-plan" v-model="newPermission.policy_fk" placeholder="Please select from the list..."
                                  optionLabel="label" optionValue="value" optionGroupLabel="label"
                                  optionGroupChildren="items"
                                  :options="policyOptions" @blur="v$.newPermission.policy_fk.$touch">
                          <template #optiongroup="slotProps">
                            <div class="flex align-items-center">
                              <div>{{ slotProps.option.label }}</div>
                            </div>
                          </template>
                        </Dropdown>
                      </div>
                      <div v-if="v$.newPermission.policy_fk.$error" class="form-error">Please ensure a plan is
                        provided
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="checkbox" data-cy="permission-checkbox-confirm">
                    <Checkbox style="margin-top:3px;" name="permission_confirm" :value="newPermission.confirmed"
                              :binary="true" v-model="newPermission.confirmed"/>
                    <label>I confirm I give permission to the names person to view my selected holdings through the chosen
                      expiry date.</label>
                  </div>
                  <div v-if="v$.newPermission.confirmed.$error" class="form-error">Please confirm permission</div>
                </div>
              </ScrollPanel>
            </div>
          </div>
          <div class="flex justify-content-between my-3 btn-group" style="width: 100%">
            <Button class="clarity-btn clarity-gradient-grey" @click="closeModal">Dismiss</Button>
            <submit-button data-cy="permission-modal-submit" style="padding: 0 45px;" @submitted="submitForm" :loading="loadingState" button-text="Save Permission" ></submit-button>
          </div>
        </template>
      </content-card-full>
    </template>
  </Modal>
</template>
<script>
import {reactive, computed, ref, watch} from "vue";
import Modal from "@/components/common/Modal";
import ContentCardFull from '@/components/common/ContentCardFull'
import CardTitle from '@/components/common/CardTitle'
import useVuelidate from '@vuelidate/core'
import {required} from "@vuelidate/validators";
import {useStore} from "vuex";
import dayjs from "dayjs";
import api from "@/core/services/api";
import {toDrupalRequest} from "@/core/helpers";
import SubmitButton from "../common/layout/interactive/SubmitButton";

export default {
  name: 'AddPermissionModal',
  components: {
    ContentCardFull,
    CardTitle,
    Modal,
    SubmitButton
  },
  emits: ['persistPermission'],
  setup(props, context) {
    const store = useStore()
    const today = dayjs().toDate();

    const closeModal = () => {
      store.dispatch('setActiveModal')
    }

    const relationshipOptions = [
      {value: 'Spouse/Partner'},
      {value: 'Child'},
      {value: 'Grandchild'},
      {value: 'Parent'},
      {value: 'Grandparent'},
      {value: 'Sibling'},
      {value: 'Other'},
    ]

    const loadingState = computed(() => store.getters.isLoading('PermissionsModal'))

    const portfolioOptions = computed(() => {
      const options = store.state.selections.portfolioOptions;
      if (options && options.error) {
        store.dispatch('addNotification', {severity: 'error', summary: 'Portfolio Select', detail: options.message})
        return [];
      } else {
        return options
      }
    })

    const policyOptions = ref([])
    const selectedPortfolio = ref()

    watch(selectedPortfolio, (val) => {
      const stateOptions = store.state.selections.policyOptions;
      if (stateOptions && stateOptions.error) {
        store.dispatch('addNotification', {severity: 'error', summary: 'Policy Select', detail: stateOptions.message})
        return [];
      } else {
        const options = []
        const pens = [];
        const isa = [];
        const nonpens = [];
        stateOptions.forEach((policy) => {
          if (val.portfolioplans.includes(policy.planstring)) {
            if (policy.productType === 'pens') {
              pens.push({label: policy.name, value: policy.planstring});
            } else if (policy.productType === 'isa') {
              isa.push({label: policy.name, value: policy.planstring});
            } else if (policy.productType === 'nonpens') {
              nonpens.push({label: policy.name, value: policy.planstring});
            }
          }
        })

        if (pens.length > 0) {
          options.push({
            label: "Pension",
            items: pens
          })
        }
        if (isa.length > 0) {
          options.push({
            label: "ISA",
            items: isa
          })
        }
        if (nonpens.length > 0) {
          options.push({
            label: "Non Pension",
            items: nonpens
          })
        }
        policyOptions.value = options
      }
    })

    const newPermission = reactive({
      uuid: null,
      email: null,
      name: null,
      relationship: null,
      activity_type: '',
      policy_fk: null,
      expires: null,
      confirmed: false
    })

    const rules = computed(() => {
      const localRules = {
        newPermission: {
          email: {required},
          name: {required},
          relationship: {required},
          activity_type: {required},
          expires: {},
          confirmed: {
            checked: value => value === true
          }
        }
      }

      if (newPermission.activity_type !== 'FULL_ACCESS') {
        localRules.newPermission.policy_fk = { required }
      }

      return localRules
    })

    const v$ = useVuelidate(rules, {newPermission: newPermission})

    const setType = (type) => {
      if (type.value.name === 'All Holdings') {
        newPermission.activity_type = 'FULL_ACCESS'
        newPermission.policy_fk = null
      } else {
        newPermission.activity_type = 'PLAN_ACCESS'
      }
    }

    const getType = () => {
      return newPermission.activity_type === 'FULL_ACCESS' ? 'All Holdings' : selectedPortfolio.value
    }

    const noExpiry = () => {
      newPermission.expires = null
    }

    const resetForm = () => {
      newPermission.uuid = null
      newPermission.email = null
      newPermission.name = null
      newPermission.relationship = null
      newPermission.activity_type = ''
      newPermission.policy_fk = null
      newPermission.expires = null
      newPermission.confirmed = false
    }

    const prependZero = (number) => {
      return number < 10 ? `0${number}` : number
    }

    // returns date in YYYY-MM-DD
    const formatDateForPosts = (dateString) => {
      let date = new Date(dateString)

      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()

      // to +1 to month as 0 indexed
      return `${year}-${prependZero(month)}-${prependZero(day)}`
    }

    // returns date in DD/MM/YYYY
    const formatDateForTable = (date) => {
      const dateArr = date.split('-')

      return `${dateArr[2]}/${dateArr[1]}/${dateArr[0]}`
    }

    const submitForm = async () => {
      v$.value.$touch();
      if (v$.value.$invalid) return

      store.dispatch('setLoading', {id: 'PermissionsModal', state: true})
      if (process.env.VUE_APP_DELAY_BACKEND_API) {
        await new Promise(r => setTimeout(r, 2000));
      }

      if (newPermission.expires !== null) {
        newPermission.expires = formatDateForPosts(newPermission.expires)
      }
      newPermission.relationship_name = newPermission.relationship;

      const endpoint = '/permissions/client/investmentpermissionsgranted';
      const response = await api.post(
          `${toDrupalRequest(endpoint)}`,
          JSON.stringify(newPermission),
          {
            headers: {
              'Content-Type': 'application/json'
            }
          })
      if (response) console.log('tesst response', response)
      if (response.success === false || !response.success) {
        store.dispatch('setLoading', {id: 'PermissionsModal', state: false})
        await store.dispatch('addNotification', {
          severity: 'error',
          summary: 'Add Permission Error',
          detail: `Failed to add permission. Please check that the email address used has an active clarity website account.`,
        })
      }

      if (response.success !== false) {
        // need date check as calendar will default to 01/01/1970
        context.emit('persistPermission', {
          ...newPermission,
          permission_uuid: response.success,
          expires: new Date(newPermission.expires) < new Date(today) ? null : formatDateForTable(newPermission.expires)
        })
        await store.dispatch('addNotification', {
          severity: 'success',
          summary: 'Permission Saved',
          detail: ``,
          life: 4000
        })
        resetForm()
        closeModal()
        store.dispatch('setLoading', {id: 'PermissionsModal', state: false})
      }
    }

    return {
      closeModal,
      newPermission,
      v$,
      policyOptions: computed(() => policyOptions.value),
      portfolioOptions,
      relationshipOptions,
      setType,
      getType,
      selectedPortfolio,
      today,
      noExpiry,
      submitForm,
      loadingState,
    }
  }
}
</script>

<style scoped lang="scss">
.modal:deep(.modal-close) {
  display: none !important;
}

.modal:deep(.content-title) {
  padding: 10px 20px 10px 30px;
}

.modal:deep(.card-title) {
  padding: unset;
  height: unset;
  align-items: unset;
}

.modal:deep(.card-title h3) {
  margin-right: 15px;
}

.modal:deep(.card-title::before) {
  height: unset;
  width: unset;
  margin-right: unset;
  background-color: unset !important;
}

.filter-group {
  display: flex;
  justify-content: space-evenly;
  width: 100%;

  .filter-set {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 10px 0;

    &:first-of-type {
      margin-right: 20px;
    }

    label {
      margin-bottom: 15px;
      font-weight: bold;
      font-size: 18px;
      display: flex;
      align-content: center;
    }
  }
}

.btn-group button {
  padding: 15px 45px;
}

.wrapper {
  position: relative;
}

.calendar-icon {
  position: absolute;
  top: calc(50% - 12px);
  right: 20px;
}

.wrapper:deep(.p-inputtext) {
  border-right: solid;
  border-right-color: var(--clarity-light-grey);
  border-right-width: 1px;
}

.wrapper:deep(.p-inputtext):enabled:focus,
.wrapper:deep(.p-inputtext):hover {
  border-right: solid;
  border-right-color: var(--clarity-grey);
  border-right-width: 1px;
}

.checkbox {
  display: flex;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  align-items: center;

  label {
    margin-left: 15px;
    font-size: 18px;
  }
}

.form-error {
  color: var(--clarity-red);
  font-weight: bold;
  margin-top: 5px;
  margin-left: 5px;
}

</style>
